import React from 'react';
import Layout from '../components/Layout';
import Form from '../components/Form';

import Scroll from '../components/Scroll';

import logo from '../assets/img/whitelogo.png';
import darren from '../assets/images/darren-emerick.jpg';
import jr from '../assets/images/jr.jpg';

import config from '../../config';
const IndexPage = () => (

  <Layout fullMenu>
    <section id="banner">
      <div className="inner">
        <div className='col-4 image fit'><h2><img src={logo} alt="Frank A. Smith Nurseries" /></h2></div>

        <p>{config.subHeading}</p>
        <Scroll type="id" element="one">
          <a href="#one" className="button">
            About
          </a>
        </Scroll>
        <a href="/growing" className="button">What we're growing</a>

      </div>
      <Scroll type="id" element="two">
        <a href="#two" className="more">
          Get in touch
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style5">
      <div className="inner">
        <header className="major">
          <p>
            Frank A. Smith Nurseries, Inc. is a grower and re-wholesale nursery whose goal is to provide high quality perennials, hostas, groundcovers, ferns, ornamental grasses,and woody ornamentals to the metro Atlanta area. We have readily available numbers and convenient sizes at a reasonable price.
            <br />
            We strive to deliver our finished product in a professional and timely manner, in our own trucks.
          </p>
        </header>

        <h3>We're well rooted</h3>
        <p>
          In the late 1930’s, Frank A. Smith started a landscape business and nursery. The original location was at the corner of Peachtree and Pharr roads in Buckhead. Over the years, Mr. Smith installed many residential and commercial landscapes throughout north Atlanta and the nursery portion of the business grew. In the late 40’s, the business was moved to the East Chastain neighborhood along Roswell Rd, in what would later become the Pike Nurseries location next to Weinstocks Flowers.
        </p>
        <p>
          By the mid 80’s, having been solely a nursery for many years, the business was sold and moved north again, into the Dunwoody area off Jett Ferry Road. Under new ownership, JR Richardson began his long career with Frank A. Smith Nurseries. During these years, the nursery began to specialize in groundcover and Frank A. Smith Nurseries carried on its tradition of top quality material and service.
        </p>
        <p>
          In 2006, JR Richardson with a group of investors purchased the business. We have continued to grow offering a full line of groundcovers and perennials.
        </p>
        <h3>Team</h3>
        <ul className="features">
          <li className="">

            <div className="image fit">
              <img src={jr} alt="" />
            </div>
            <h3>JR</h3>
            <p>JR Richardson
              <br />
              (770) 352-4407
              <br />
              jr@fasnurseries.com
            </p>
          </li>

          <li className="">
            <div className="image fit">
              <img src={darren} alt="" />
            </div>
            <h3>Darren</h3>
            <p>Darren Emerick
              <br />
              (770) 530-5078
              <br />
              darren@fasnurseries.com
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section id='two' className="wrapper style5 special">
      <div className="inner">
        <h3>Get in touch</h3>
        <div className="icon solid fa-map-marker-alt">  1327 Rock Mountain Blvd, Stone Mountian, GA  30083</div>
        <div className="icon solid fa-phone-square">  Office: (770) 454-7994</div>

      </div>
      <hr />
      <div className='inner'>
        <Form></Form>
      </div>
    </section>


  </Layout>
);

export default IndexPage;
